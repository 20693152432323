<script setup lang="ts">
import u from "@/lib/util.js";
import WorkFeature from "@/work/components/work-feature.vue";
import {defineEmits, defineProps, reactive} from "vue";

const emit = defineEmits(['toggle']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  docs: {
    type:Array,
  }
});

const files = reactive([]);

</script>

<template>
  <work-feature :icon="'$attachments'" label="Documents" class="work-attach" :open="props.open" @toggle="emit('toggle',$event)">
    <div class="d-table w-100" @click.stop="" v-if="props.docs?.length>0">
      <div class="d-table-row">
        <div class="d-table-cell header pr-1">Type</div>
        <div class="d-table-cell header pl-1 pr-1">Name</div>
        <div class="d-table-cell header pl-1 pr-1">Size</div>
        <div class="d-table-cell header pl-1 pr-1">Date</div>
        <div class="d-table-cell header pl-1 pr-1">By</div>
        <div class="d-table-cell header"></div>
      </div>
      <div v-for="s in props.docs" class="d-table-row">
        <div class="d-table-cell pr-1">{{s.type}}</div>
        <div class="d-table-cell pl-1 pr-1 border-s">{{s.name}}</div>
        <div class="d-table-cell pl-1 pr-1 border-s">{{s.size}}</div>
        <div class="d-table-cell pl-1 pr-1 border-s">{{s.created}}</div>
        <div class="d-table-cell pl-1 pr-1 border-s">{{s.createdBy}}</div>
        <div class="d-table-cell header text-right pr-2"><v-icon icon="$delete" @click.stop="onDeleteAddress(s)"></v-icon></div>
      </div>
    </div>
      <v-card class="mt-2 pt-3">
        <v-card-text>
          <v-file-input
            v-model="files"
            color="deep-purple-accent-4"
            counter
            label="Upload Documents"
            multiple
            placeholder="Select your files"
            :prepend-icon="'$attachments'"
            variant="outlined"
            :show-size="1000"
          >
            <template v-slot:selection="{ fileNames }">
              <template v-for="(fileName, index) in fileNames" :key="fileName">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple-accent-4"
                  label
                  size="small"
                  class="me-2"
                >
                  {{ fileName }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline text-grey-darken-3 mx-2"
                >
          +{{ files.length - 2 }} File(s)
        </span>
              </template>
            </template>
          </v-file-input>
      </v-card-text>
    </v-card>
  </work-feature>
</template>

<style scoped lang="scss">
.work-note {
  .v-card {
    background-color: #333333;
  }
  .v-card-text {
    padding-bottom: 0;
    padding-top: .25rem;
  }
  .v-card-actions {
    padding-top: 0rem;

    .v-label {
      font-size: .7rem;
    }
    .v-card-subtitle {
      font-size: .7rem;
      padding-left: .45rem;
    }
    .v-btn {
      margin-top: -1.2rem;
    }
  }

  .v-col-1 {
    min-width: 9rem;
  }
}
</style>
