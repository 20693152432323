<script setup lang="ts">
import u from "@/lib/util.js";
import WorkFeature from "@/work/components/work-feature.vue";
import {defineEmits, defineProps, reactive} from "vue";

const emit = defineEmits(['toggle']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  fees: {
    type:Array,
  }
});

//const values = reactive({open:props.open});

function determineAmount(fee) {
  console.log(fee)
  if(fee.calcCode==='FLATFEE'){
    return u.formatCurrency(fee.details[0].amount);
  }
  return '';
}

</script>

<template>
  <work-feature :icon="'$fees'" label="Fees" :open="props.open" @toggle="emit('toggle',$event)">
    <div class="d-table w-100">
      <div class="d-table-row">
        <div class="d-table-cell header pr-1">Code</div>
        <div class="d-table-cell header pr-1">Description</div>
        <div class="d-table-cell header pr-1">Rate</div>
        <div class="d-table-cell header pr-1">Factor</div>
        <div class="d-table-cell header pr-1">Amount</div>
      </div>
      <div v-for="fee in props.fees" class="d-table-row">
        <div class="d-table-cell pr-1">
          {{fee.code}}
        </div>
        <div class="d-table-cell pl-1 pr-1 border-s">
          {{fee.name}}
        </div>
        <div class="d-table-cell pl-1 pr-1 border-s">

        </div>
        <div class="d-table-cell pl-1 pr-1 border-s">

        </div>
        <div class="d-table-cell pl-1 pr-1 border-s text-right">
          {{determineAmount(fee)}}
        </div>
      </div>
    </div>
  </work-feature>
</template>

<style scoped lang="scss">
</style>
