<template>
  <top-nav :theme="data.theme" :module="data.sectionMode" @theme="onChangeTheme">
    <v-responsive class="w-100 home">
      <div class="pa-2">
        <router-view></router-view>
      </div>
    </v-responsive>
  </top-nav>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, watch } from 'vue'
import TopNav from "@/components/topNav";

const props=defineProps({
});

const data = reactive({
  oid:'',
  theme:'dark'
});

function onChangeTheme() {
  data.theme=data.theme==="dark"?"light":"dark";
  localStorage.setItem('theme',data.theme);
}

onMounted(async ()=>{
  data.theme=localStorage.getItem('theme') || 'dark';
  data.oid=localStorage.getItem('oid');
});
</script>

<style lang="scss">

</style>
