<script setup lang="ts">
import {defineProps, defineEmits, reactive} from 'vue'

const emit = defineEmits(['toggle']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  label: {
    type:String,
    default:""
  },
  icon: {
    type:String,
    default:undefined
  }
});

const values = reactive({open:props.open});

function onClick() {
  values.open=!values.open;
  emit('toggle',values.open);
}

function headClass(c) {
  return c+(values.open?' open':' close');
}

</script>

<template>
  <div :class="headClass('v-row work-feature work-feature-back')" test-id="fees" @click="onClick" :aria-label="props.label" role="button" :aria-pressed="values.open">
    <div class="v-col ml-3 unselectable work-feature-label"><v-icon v-if="props.icon!==undefined" :aria-label="'icon '+(props.icon||'').replace('$','')" :icon="props.icon" class="panel-icon"></v-icon>{{props.label}}</div>
    <div class="v-col-1 text-right"><slot name="headAction"></slot><v-icon class="ml-3" aria-label="open" icon="$up" v-if="values.open"></v-icon><v-icon class="ml-3" aria-label="closed" icon="$down" v-if="!values.open"></v-icon></div>

    <div class="v-col-12 work-feature-back pt-0" v-if="values.open" @click.stop>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.d-table-cell.header {
  font-size: .7rem;
  font-weight: bold;
  color: #666666;
}

.work-feature {
  margin-top: 0;
  .panel-icon {
    margin-left: -1rem; margin-right:.25rem
  }
}
.work-feature-back {
  background-color: #888888;
  margin-bottom: .5rem !important;
}
.v-theme--dark {
  .work-feature-back {
    background-color: #222222;
  }
}
</style>
