<script setup lang="ts">
import u from "@/lib/util.js";
import WorkFeature from "@/work/components/work-feature.vue";
import {defineEmits, defineProps, reactive} from "vue";
import FieldSet from "@/components/fieldSet.vue";

const emit = defineEmits(['toggle']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  fieldSets: {
    type:Array,
  }
});

const values = reactive({open:props.open});

function toggle(e){
  emit('toggle',e);
  values.open=e;
}

</script>

<template>
  <work-feature :icon="'$fields'" label="Additional Details" class="work-additional" :open="values.open" @toggle="toggle">
    <div v-if="values.open" v-for="s in props.fieldSets" @click.stop="">
      <v-label>{{s.code}}&nbsp;-&nbsp;{{s.name}}</v-label>
      <v-row>
        <div class="v-col">
          <field-set class="v-col" :field-set="s" ></field-set>
        </div>
      </v-row>
    </div>
  </work-feature>
</template>

<style lang="scss">
.work-additional{
  .panel-icon {
    padding-left: 0.25rem;
  }
}
</style>
