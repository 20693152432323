<template>
  <v-card class="ml-3 w-50 mt-4" variant="outlined">

    <v-btn-toggle
      v-model="values.workDiscipline"
      @update:modelValue="onDisciplineChange"
      rounded="0"
      color="deep-purple-accent-3"
      group
      mandatory
    >
      <v-btn v-for="d in disciplines" :key="d.id" :value="d.code" >
        {{d.name}}
      </v-btn>
    </v-btn-toggle>

    <v-autocomplete class="v-col"
              :items="filteredTypes"
              id="type-select"
              name="workFlowType"
              :item-title="typeTitle"
              item-value="id"
              density="compact"
              v-model="values.tid"
              @update:modelValue="onTypeChange"
              hide-details
              aria-label="Type"
              label="Type"
              v-if="form.loaded"
    ></v-autocomplete>

    <v-autocomplete class="v-col"
                    :items="filteredSubTypes"
                    id="sub-type-select"
                    name="workFlowSubType"
                    :item-title="subtypeTitle"
                    item-value="id"
                    density="compact"
                    v-model="values.stid"
                    @update:modelValue="onSubTypeChange"
                    hide-details
                    aria-label="Sub Type"
                    label="Sub Type"
                    hide-no-data
                    v-if="form.loaded"
    ></v-autocomplete>

    <v-autocomplete class="v-col"
                    :items="addresses"
                    id="address-select"
                    autocomplete="off"
                    aria-autocomplete="none"
                    name="workFlowAddress"
                    item-value="id"
                    item-title="location"
                    density="compact"
                    v-model="values.location"
                    @update:modelValue="onSelectAddress"
                    @update:search="onSearchAddress"
                    @click:append-inner="onMapClick"
                    :hide-details="form.addressHint===null || form.addressHint===''"
                    aria-label="Address"
                    label="Address"
                    :hint="form.addressHint"
                    hide-no-data
                    append-inner-icon="$map"
                    v-if="form.loaded"
    ></v-autocomplete>


    <v-card-actions class="flex-row-reverse">
      <v-btn variant="flat" class="mr-1" @click="onCreate" color="primary">Create</v-btn>
      <v-btn variant="outlined" class="mr-1" @click="onClear">Clear</v-btn>
    </v-card-actions>
    <v-dialog v-model="form.map" width="auto">
      <map-select @close="form.map=false" @save="onSaveLocation" :return-bounds="false" :return-address="true"></map-select>
    </v-dialog>
  </v-card>
</template>

<script setup lang="ts">
import {ref, reactive, onMounted, defineProps, watch, nextTick} from 'vue'
import u from '@/lib/util';
import {db} from "@/lib/db";
import { useRouter, useRoute } from 'vue-router'
import MapSelect from "@/map/mapSelect.vue";
import AddressLookupFactory, {IAddressLookup, LookupRequest} from "@/lib/address/addressLookup";
import axios from "axios";
import {v4 as uuid4} from "uuid";

const router = useRouter();
const route = useRoute();
const GOOGLE_MAPS_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY;
const endpoint = import.meta.env.VITE_TARGET;

const props=defineProps({
  appFlag:String
})

const list = reactive([{
  name: "Loading.."
}]);

let addressLookup:IAddressLookup;
const form = reactive({loaded:false,map:false,addressHint:''});
const values = reactive({tid:'',stid:'',workDiscipline:'',address:{},location:'',x:null,y:null});
const addresses = reactive([]);
const disciplines = reactive([]);
const types = reactive([]);
const filteredTypes = reactive([]);
const subtypes = reactive([]);
const filteredSubTypes = reactive([]);

function required (v) {
  return !!v || 'Field is required'
}

async function refreshed() {
  await db.reload(disciplines,"workDiscipline",'pos');
  await db.reload(types,"workFlowType",'code');
  await db.reload(subtypes,"workFlowSubType",'code');

  filterTheTypes();
  form.loaded=true;
}
function typeTitle(item) {
  if(item.code || item.name)
    return item.code+" - "+item.name;

  if(values.tid) {
    let selected = u.find(types, {id: values.tid});
    if (selected) {
      return typeTitle(selected);
    }
  }
  return '';
}

function subtypeTitle(item) {
  if(item.code || item.name)
    return item.code+" - "+item.name;

  if(values.stid) {
    let selected = u.find(subtypes, {id: values.stid});
    if (selected) {
      return typeTitle(selected);
    }
  }
  return '';
}

function onDisciplineChange(code) {
  if(code) {
    localStorage.setItem("lastWorkDiscipline",code);
    values.tid='';
    values.stid='';
    filterTheTypes();
  }
}

function filterTheTypes() {
  let d = values.workDiscipline;
  let appFlag=props.appFlag;
  let list = u.filter(types,(typ)=>{
    return typ.flags!=null && typ.flags.indexOf(d)>=0 && typ.flags.indexOf(appFlag)>=0 && typ.flags.indexOf("EXPIRED")<0;
  });
  u.cleanExtend(filteredTypes,list);
  filterTheSubTypes();
}
function onTypeChange(tid) {
  values.tid=tid;
  localStorage.setItem("lastWorkTypeID",tid);
  filterTheSubTypes();
}

function filterTheSubTypes() {
  let d = values.tid;
  let appFlag=props.appFlag;
  let list = u.filter(subtypes,(typ)=>{
    return typ.flags!=null && typ.flags.indexOf(appFlag)>=0 && typ.workFlowTypeID===d && typ.flags.indexOf("EXPIRED")<0;
  });
  u.cleanExtend(filteredSubTypes,list);
}
function onSubTypeChange(tid) {
  localStorage.setItem("lastWorkSubTypeID",tid);
  values.stid=tid;
}

async function onCreate() {
  values['userName']="Doty, Chris";
  let oid=localStorage.getItem("oid");
  values['oid']=oid;
  values['id']=uuid4();

  localStorage.setItem("edit-create",JSON.stringify(values));  // load create details so we can preload page
  axios.post(endpoint + '/workFlow/' + oid,values).then(async (r) => {
    let response = r.data[0];
    localStorage.setItem("edit-number",response.number); // preload wf number
    localStorage.setItem("edit-last",response.id);       // preload wf id
    await router.push({
      name: 'workEdit',
      params: response
    });
  })
}

function onClear(){
  values.tid='';
  values.stid='';
  localStorage.setItem("lastWorkTypeID",'');
  localStorage.setItem("lastWorkSubTypeID",'');
}

function onSelectAddress(v) {
  if(addresses.length>v) {
    onSaveLocation(addresses[v]);
  }
}

function onSearchAddress(txt){
  if(!txt || txt.length<5) return;
  let request = new LookupRequest();
  request.address=txt;
  addressLookup.lookup(request).then((results)=>{
    for(let i=0;i<results.length;i++) {
      results[i].id=i;
    }
    u.cleanExtend(addresses,results);
  });
  console.log(txt);
}

function onMapClick() {
  form.map=true;
}

function onSaveLocation(a) {
  if(a.x!==undefined && a.x!==null) {
    form.addressHint='x: '+a.x+' / y: '+a.y;
  }
  else form.addressHint=null;
  u.extend(values,a);
  form.map=false;

  u.focusByID('address-select');
}

onMounted(async ()=>{
  localStorage.removeItem("create-workflow");
  values.workDiscipline=localStorage.getItem("lastWorkDiscipline") || 'PERMIT';
  values.tid=localStorage.getItem("lastWorkTypeID")
  values.stid=localStorage.getItem("lastWorkSubTypeID")

  form.loaded = false;
  await refreshed();

  addressLookup = await AddressLookupFactory.getDefault();
  addressLookup.init({apiKey:GOOGLE_MAPS_KEY});
});
</script>

<style lang="scss" scoped>
</style>
