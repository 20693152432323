<script setup>
import {defineProps, onMounted, reactive} from 'vue'
import u from '@/lib/util';

const props=defineProps({
  fieldSet:Object
})

const fieldSet = reactive({fields:[]});

onMounted(async ()=>{
  u.cleanExtend(fieldSet,props.fieldSet);
});
</script>

<template>
  <div class="field-set" :id="fieldSet.id">
    <div v-for="field in fieldSet.fields">
      <div v-if="field.isVisible==='Y'" class="pb-0">
        <div v-for="subfield in field.fieldType">
          <v-text-field :name="field.name" :label="field.label" v-if="subfield==='text'" hide-details="" density="compact"></v-text-field>
          <v-textarea name="field.name" :label="field.label" v-if="subfield==='textarea'" hide-details="" density="compact"></v-textarea>
          <v-select name="field.name" :items="field.list" :label="field.label" v-if="subfield==='list'" hide-details="" density="compact"></v-select>
          <v-text-field name="field.name" :label="field.label" v-if="subfield==='quantity'" hide-details="" density="compact"></v-text-field>
          <v-text-field name="field.name" :label="field.label" v-if="subfield==='number'" hide-details="" density="compact"></v-text-field>
          <v-text-field name="field.name" :label="field.label" v-if="subfield==='date'" type="date" hide-details="" density="compact"></v-text-field>
          <v-radio-group name="field.name" v-if="subfield==='yesno'" hide-details="" density="compact" inline>
            {{field.label}}&nbsp;&nbsp;
            <v-radio label="Yes" value="Y"></v-radio>
            <v-radio label="No" value="B"></v-radio>
          </v-radio-group>

          <v-checkbox name="field.name" :label="field.label" v-if="subfield==='checkbox'" hide-details="" density="compact"></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
