<script setup lang="ts">
import u from "@/lib/util.js";
import WorkFeature from "@/work/components/work-feature.vue";
import {defineEmits, defineProps, reactive} from "vue";
import FieldSet from "@/components/fieldSet.vue";

const emit = defineEmits(['toggle']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  contractors: {
    type:Array,
  }
});

const values = reactive({open:props.open});

function toggle(e){
  emit('toggle',e);
  values.open=e;
}

function onAddContractor() {

}

function onDeleteContractor(address) {

}

</script>

<template>
  <work-feature :icon="'$contractor'" label="Contractors" :open="values.open" @toggle="toggle">
    <template v-slot:headAction>
      <v-icon aria-label="closed" v-if="values.open" icon="$add" @click.stop="onAddContractor"></v-icon>
    </template>
    <template v-slot:default>
      <div class="d-table w-100" @click.stop="">
        <div class="d-table-row">
          <div class="d-table-cell header pr-1">Type</div>
          <div class="d-table-cell header pl-1 pr-1">Name</div>
          <div class="d-table-cell header pl-1 pr-1">Phone</div>
          <div class="d-table-cell header"></div>
        </div>
        <div v-for="s in props.contractors" class="d-table-row">
          <div class="d-table-cell pr-1">{{s.type}}</div>
          <div class="d-table-cell pl-1 pr-1 border-s">{{s.name}}</div>
          <div class="d-table-cell pl-1 pr-1 border-s">{{s.phone}}</div>
          <div class="d-table-cell header text-right pr-2"><v-icon icon="$delete" @click.stop="onDeleteContractor(s)"></v-icon></div>
        </div>
      </div>
    </template>
  </work-feature>
</template>

<style scoped lang="scss">
</style>
