<template>
  <div class="w-100 flow-sections">
    <div class="v-row work-header work-header-back mb-2" @click="values.headerOpen=!values.headerOpen" role="button" :aria-pressed="values.headerOpen">
      <div class="v-col-3">{{work.typeCode}}-{{work.number}}<v-icon class="ml-1" icon="$copy" size="x-small" @click.stop="u.copyTextToClipboard(work.number)"></v-icon> <span class="ml-4" :title="work.name">({{work.code}})</span></div>
      <div class="v-col-6 text-center">{{work.location}}<v-icon class="ml-1" icon="$copy" size="x-small" @click.stop="u.copyTextToClipboard(work.location)"></v-icon></div>
      <div class="v-col-2 text-right" :title="u.formatDateTimeLong(work.created)">{{u.formatDate(work.created)}}</div>
      <div class="v-col-1 text-right"><v-icon icon="$up" v-if="values.headerOpen"></v-icon><v-icon icon="$down" v-if="!values.headerOpen"></v-icon></div>

      <v-col class="v-col-12 work-header-back" v-if="values.headerOpen" @click.stop="">
        <v-row class="pl-3">
          <v-card>
            <address-panel v-for="a in work.addresses" :address="a" title="Primary Address" class="v-col">
            </address-panel>
          </v-card>
          <v-card class="add">
            <v-card-subtitle class="pt-2">Add Contractor</v-card-subtitle>
            <v-btn icon="$add" flat></v-btn>
          </v-card>
        </v-row>
        <v-row>
          <div class="v-col mb-0 pb-0 work-default-text">
            <v-expansion-panels class="v-col">
              <v-expansion-panel title="Default Text" :text="work.defaultText"></v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-row>
        <v-row v-for="s in work.generalFieldSets">
          <field-set class="v-col" :field-set="s" ></field-set>
        </v-row>
      </v-col>
    </div>
    <work-addresses :open="values.addressOpen" :addresses="work.addresses" @toggle="values.addressOpen=$event"></work-addresses>
    <work-additional :open="values.additionalOpen" :fieldSets="work.fieldSets" @toggle="values.additionalOpen=$event"></work-additional>
    <work-contractors :open="values.contractorsOpen" :addresses="work.contractors" @toggle="values.contractorsOpen=$event"></work-contractors>
    <work-workflow :open="values.tasksOpen" :taskSets="work.taskSets" @toggle="values.tasksOpen=$event"></work-workflow>
    <work-fees :open="values.feesOpen" :fees="work.fees" @toggle="values.feesOpen=$event"></work-fees>
    <work-notes :open="values.notesOpen" :notes="work.notes" @toggle="values.notesOpen=$event"></work-notes>
    <work-attach :open="values.docsOpen" :notes="work.docs" @toggle="values.docsOpen=$event"></work-attach>

    <div class="v-row pl-4 pr-4">
      <div v-for="(v,k) in progress" :class="'pl-2 pr-2 v-col pt-0 pb-0 ' + (v===1?'done':(v===2?'mock':'notdone'))+(work.features && work.features.indexOf(k)>=0?' active-feature':' inactive-feature')">{{k}}</div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, reactive} from 'vue'
import u from '@/lib/util';
import {db} from "@/lib/db";
import axios from "axios";
import addressPanel from "@/components/addressPanel.vue";
import FieldSet from "@/components/fieldSet.vue";
import WorkFeature from "@/work/components/work-feature.vue";
import WorkNotes from "@/work/components/work-notes.vue";
import WorkFees from "@/work/components/work-fees.vue";
import WorkWorkflow from "@/work/components/work-workflow.vue";
import WorkAdditional from "@/work/components/work-additional.vue";
import WorkAddresses from "@/work/components/work-addresses.vue";
import WorkContractors from "@/work/components/work-contractors.vue";
import WorkAttach from "@/work/components/work-attach.vue";

const endpoint = import.meta.env.VITE_TARGET;

const props=defineProps({
  id:String,
  number:String
})

const mock = {
  notes:[{
    comment:"Nostrum velit vero enim. Fuga adipisci fuga non et sed corporis exercitationem inventore. Consectetur officia et eos fuga incidunt quasi. Unde enim itaque repellendus reiciendis error sit tempora magni. Ab fugit repellendus voluptatibus facilis sit in. Voluptatum exercitationem optio dolor eligendi iusto et nam qui.",
    created:new Date(),
    createdBy:"Chris Doty"
  }]
}

const values   = reactive({headerOpen:false,additionalOpen:false,tasksOpen:false,feesOpen:false,notesOpen:false,contractorsOpen:false,docsOpen:false});
const current  = reactive({});
const work = reactive({defaultText:'',fieldSets:[],taskSets:[],generalFieldSets:[],addresses:[],docs:[],notes:[],contractors:[]});

const progress=reactive({
    'ADDRESS':2,
    'ADDRESSES':2,
    'CONDITIONS':0,
    'CONTRACTOR':2,
    'CONTRACTORS':2,
    'FEES':1,
    'FLAGS':0,
    'INSTRUMENT':0,
    'NOTES':2,
    'PAYMENT':0,
    'PEOPLE':0,
    'TASKS':1,
    'ATTACH':2,
    'VIOLATIONS':0,
    'ADDITIONAL':1,
    'LICENSE':0
})


function required (v) {
  return !!v || 'Field is required'
}

async function refreshed() {
  values.loaded=true;
}

onMounted(async ()=>{
  values.loaded = false;
  values.number = '';
  if(!values.number && props.id===localStorage.getItem("edit-last"))
    values.number = localStorage.getItem("edit-number");
  localStorage.setItem("edit-number",values.number); // save in case page refreshed
  localStorage.setItem("edit-last",props.id); // save in case page refreshed
  current.oid=localStorage.getItem("oid");

  axios.get(endpoint+"/workFlow/"+current.oid+"/"+props.id).then(async (response) => {
    u.cleanExtend(work, response.data,mock);

    let sets = work.taskSets || [];
    for (let i = 0; i < sets.length; i++) {
      let set = sets[i];
      let tasks = set.tasks || [];
      for (let j = 0; j < tasks.length; j++) {
        let task = tasks[j];
        task.resultCode='';
        await db["taskResultSet"].where({oid: current.oid, id: task["taskResultSetID"]}).each(item => {
          task.resultSet=item.choices||[];
        });
      }
      set.tasks=tasks;
    }
    work.taskSets = sets;
   });

  await refreshed();
});
</script>

<style lang="scss">
.flow-sections {
  font-size: 0.90rem;
}
.panel-icon {
  margin-left: -1rem; margin-right:.25rem
}
.pl-0 {
  .v-expansion-panel-text__wrapper {
    padding-left: 0 !important;
  }
}
.pr-0 {
  .v-expansion-panel-text__wrapper {
    padding-right: 0;
  }
}
.v-theme--dark {
  .task-result {
    border: solid 1px #888888;
    color: white;

    background-color: transparent;
    padding: 0 1em 0 .5em;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }
}


.work-header {
  margin-top: 0;
}
.work-header-back {
  background-color: #888888;
}
.v-theme--dark {
  .work-header-back {
    background-color: #333333;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.work-default-text {
  button.v-expansion-panel-title {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;
    min-height: 1.6rem;
  }
  .v-expansion-panel-title > .v-expansion-panel--active {
    min-height: 1.6rem !important;
  }
  .v-expansion-panel-text__wrapper {
    padding-left: 1rem;
  }
}
.v-col.done {
  color: green;
}
.v-col.mock {
  color: yellow;
}
.inactive-feature {

  text-decoration: underline;
  text-underline-style: single;
  text-decoration-color: red;
}
.v-card.add {
  min-width: 7rem;
  min-height: 7rem;
  background-color: #666666;
  margin-left: .5rem;
  position: relative;
  .v-card-subtitle {
    color: black;
  }
  .v-btn {
    background-color: #666666;
    color: black;
    margin-top: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%)translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}

</style>
